import { createWebHistory, createRouter } from "vue-router";
import main from "@/router/modues/main.js";
import LayoutOthers from "@/layout/LayoutOthers.vue";
import news from "@/router/modues/news.js";
import recruit from "@/router/modues/recruit.js";
import ocare from "@/router/modues/ocare.js"; // 추가
import terms from "@/router/modues/terms.js"; // 추가
import person from "@/router/modues/person.js"; // 추가
import bcc from "@/router/modues/bcc.js";
import sales from "@/router/modues/sales.js";
import sample from "@/router/modues/sample.js";
//import { Beusable } from "@/api/thirdParty.js";
/**
 * 라우터 설정
 */
const router = createRouter({
  history: createWebHistory(),
  routes: [
    sales,
    sample,
    {
      path: "/healthCheck/HealthCheck",
      component: "@/views/main/MainPage.vue",
      beforeEnter() {
        window.location.href = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? `${process.env.VUE_APP_LINK_HEALTHCHECK}/mobile` : `${process.env.VUE_APP_LINK_HEALTHCHECK}`;
      },
    },
    {
      path: "/healthCheck",
      component: "@/views/main/MainPage.vue",
      beforeEnter() {
        window.location.href = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? `${process.env.VUE_APP_LINK_HEALTHCHECK}/mobile` : `${process.env.VUE_APP_LINK_HEALTHCHECK}`;
      },
    },
    {
      path: "/",
      //mode: "history",
      component: () => import("@/layout/LayoutHomepage"),
      meta: {
        title: "국민과 건강사이 KB헬스케어", 
        menu1Depth: [
          {
            link: { name: "OcarePage" },
            to: "/ocare",
            title: "O‘CARE",
            onoff: true,
          },
          {
            link: { name: "RecruitPage" },
            to: "/recruit",
            title: "채용",
            onoff: true,
          },
          {
            link: { name: "PressPage", query: { pageNum: "0" } },
            to: "/list/press",
            title: "소식",
            onoff: true,
          },
        ],
      },
      children: [
        main,
        ocare,
        recruit,
        news,
        person,
        bcc,
        {
          path: "error",
          component: LayoutOthers,
          children: [
            {
              path: "404",
              meta: {
                title: '404',
              },
              name: "Error404",
              component: () => import("@/views/error/404Page.vue"),
            },
            {
              path: "500",
              meta: {
                title: '500',
              },
              component: () => import("@/views/error/500Page.vue"),
            },
          ],
        },
        //Footer 링크 페이지
        {
          path: "terms",
          component: LayoutOthers,
          children: [...terms],
        },

        //잘못된 URL
        {
          path: ":pathMatch(.*)",
          redirect: { name: "Error404" },
        } 
      ],
    },
  ],
  //sensitive: true
});

/**
 * 라우팅 관리
 */
router.beforeEach((to, from, next) => {
  console.group("route " + to.path);
  console.debug("router to", to.meta.title);
  console.debug("from", from);
  console.groupEnd();

  
  //메타 동적 설정 
  document.title = to.meta.title;

  //Beusable.load(to.fullPath);

  next(); // 페이지 전환
});

export default router;
