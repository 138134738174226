export const name = "ui.ocare";

import Matter from "matter-js";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { UI } from "@/resources/js/ui.common";

gsap.registerPlugin(ScrollTrigger);

const UI_Ocare = (function(){
	let jMap = {}
	let vMap = {}
	let tl = {}
	let ani = {}
	, setMap = function(_vueTarget){
		jMap = {
			html : document.querySelector('html')
			, page: document.querySelector('[data-ui-page="ocare"]')
			, bodySection : document.querySelector('[data-ui-page="ocare"] .bodySection')
			, swiper : null
			, section01 : '[data-section="01"]'
			, section02 : '[data-section="02"]'
			, section03 : '[data-section="03"]'
			, section0302 : '[data-section="0302"]'
			, section0303 : '[data-section="0303"]'
			, section0304 : '[data-section="0304"]'
			, section04 : '[data-section="04"]'
			, section04_fix : '[data-fix-section="04"]'
			, section0402 : '[data-section="0402"]'
			, section0403 : '[data-section="0403"]'
			, section05 : '[data-section="05"]'
			, section0502 : '[data-section="0502"]'
			, section06 : '[data-section="06"]'
			, section07 : '[data-section="07"]'
			, section08 : '[data-section="08"]'
		}
		, vMap ={
			this : _vueTarget
			, startSection : 0 //swiper startSection // start: 0
			, changeScrollNum : document.querySelectorAll('.swiperSection .section.swiper-slide').length -1//swipe > body Bridge Num
			, scrollMode : 'swipe'//swipe or body
			, movieNum : 0 //swiper realIndex
			, prevNum : 0 //swiper PrevIndex
			// , scrollFlag : true
			// , matterFlag : false//swipe > body Bridge Num
			, rutinSwier_flag: true
		}
		, tl = {
			section01 : null
			, section02 : null
			, section03 : null
			, section0302 : null
			, section0303 : null
			, section0304 : null
			, section04 : null
			, section05 : null
			, section0502 : null
			, section06 : null
			, section07 : null
		}
		, ani = {
			mission : null
			, mission_marquee : null
			, stamp : null
			, magic : null
			, tazagi : null
			, pdf : null
			, arrow : null
		}
	}
	, scroll = {
		watch : function(){
			function handleScroll() {
				const top = jMap.bodySection.scrollTop;
				if (top >= 5) {
					vMap.scrollMode = 'body';
				} else {
					if (vMap.scrollMode === 'body') {
						if (UI.Pub.testFlag) console.log('[GO swiperSection] >>> >>> \n\n');
						
						jMap.bodySection.scrollTop = 0;
						document.body.scrollTop = 0;
						vMap.this.slideTo(vMap.changeScrollNum - 1);
					}
					vMap.scrollMode = 'swipe';
				}
			}

			jMap.bodySection.removeEventListener('scroll', handleScroll);
			jMap.bodySection.addEventListener('scroll', handleScroll);
			handleScroll();
		}
	}
	, swipe = {
		init: function(){
			tl.section01.play();
			if(jMap.html.getAttribute('data-ui-size') == 'mobile') ani.arrow.pause();
			else ani.arrow.play();
		}
		, change : function(_movieNum){
			if(UI.Pub.testFlag) console.log('[swiper change] movieNum: ', vMap.movieNum, ', prevNum: ', vMap.prevNum)

			const index = vMap.movieNum = _movieNum;
			const fixedComp = document.querySelector('.fixed-comp');

			//section02 ============= matter
			if(index == 0) {
				setTimeout(UI_Matter.clear, 300);
				tl.section01.play();
				if(jMap.html.getAttribute('data-ui-size') == 'mobile') ani.arrow.pause();
				else ani.arrow.play();
				section02.keyboardOff();
			}
			if(index == 1) {
				ani.arrow.pause();
				tl.section01.pause();
				// tl.section02.progress(0);
				// tl.section02.play();
				section01.setParallax();
				section02.keyboard();
			}

			//section03 ============= lottie
			if(index == 2) {
				// setTimeout(UI_Matter.clear, 300);
				// tl.section03.progress(0);
				// tl.section03.play();
				section02.keyboardOff();
				section01.clearParallax();
				ani.mission_marquee.pause();
				if(jMap.html.getAttribute('data-ui-size') == 'mobile') return;
				fixedComp.removeAttribute('style');
				fixedComp.removeAttribute('data-swiper-parallax');
			}

			// if(index == 5) {
			// 	ani.mission_marquee.pause();
			// 	tl.section0304.progress(0);
			// 	tl.section0304.play();

			// 	if(jMap.html.getAttribute('data-ui-size') == 'mobile') return;
			// 	fixedComp.removeAttribute('style');
			// 	fixedComp.removeAttribute('data-swiper-parallax');
			// }

			//[section 04]=============
			//mission
			if(index == 3) {
				tl.section04.play();
				ani.mission_marquee.play();

				if(jMap.html.getAttribute('data-ui-size') == 'mobile') return;
				motion.changeTitle(0);
				fixedComp.setAttribute('data-swiper-parallax', '100%');
			}

			//stamp
			if(index == 4) {
				ani.mission_marquee.pause();
				section0402.init();

				if(jMap.html.getAttribute('data-ui-size') == 'mobile') return;
				motion.changeTitle(1);
				fixedComp.setAttribute('data-swiper-parallax', '200%');
				setTimeout(function(){
					fixedComp.setAttribute('data-swiper-parallax', '100%');
				}, 600)
			}

			if(index == 5) {
				section0403.init();
				if(jMap.html.getAttribute('data-ui-size') == 'mobile') return;
				motion.changeTitle(2);
				fixedComp.style.transform = 'translate3d(0px, 200%, 0px)';
			}



			// if(index == 6) {
			// 	tl.section0502.pause();
			// 	tl.section05.play();
			// 	ani.tazagi.progress(0.15);
			// 	ani.tazagi.play();
			// }

			// if(index == 7) {
			// 	tl.section0502.play();
			// }



			if(index == 6) {
				// tl.section0502.pause();
				tl.section06.play();
			}

			//bodySection
			if(vMap.movieNum == vMap.changeScrollNum){
				setTimeout(function(){
					document.body.scrollTop = jMap.bodySection.offsetTop;
					jMap.bodySection.scrollTop = 6;
					vMap.scrollMode = 'body';
					scroll.watch();
					tl.section07.play();
					if(UI.Pub.testFlag) console.log('\n\n[GO bodySection] >>> >>>', jMap.bodySection.offsetTop,' \n\n');
				},300);
			}
		}
	}
	, section01 = {
		init : function(){
			const $section = jMap.page.querySelector(jMap.section01);
			const $txt = $section.querySelectorAll('.governing .txt-line');
			const $deco = $section.querySelectorAll('.intro-img .deco i');
			const $decoSmile = $section.querySelectorAll('.deco.smile i');
			gsap.set($section, {autoAlpha:0});
			tl.section01 = gsap.timeline({paused: true})
				.to($section, {autoAlpha:1})
				.from($txt, {delay:0.5, y: '6rem', autoAlpha: 0, stagger: {each: 0.15}})
				.from($deco, {y: '16rem', autoAlpha: 0, scale:0.7, ease: 'power2.inOut', stagger:{each: 0.05}, }, '-=30%')
				.fromTo($decoSmile, {y: '0'}, {y: '1.5rem', duration: 0.8, ease: 'slow', repeat: -1, yoyo:true})
			;

			//mouseMove
			/*var ARval=[
				6, -5, 4, -7, 4, 
				-3, -4, -8, 3, -3,
				2
			]

			var section01 = document.querySelector($section);
			var deco = section01.querySelectorAll('.deco');

			document.addEventListener('mousemove', (e) => {
				deco.forEach((item, i) =>{
					const icon = item.querySelector('i');
					var val = ARval[i];
					var x = ((window.innerWidth/2) - e.clientX * val) / 100;
					var y = ((window.innerHeight/2) - e.clientY * val) / 100;

					icon.style.marginLeft = x + 'px';
					icon.style.marginTop = y + 'px';
					icon.style.transform = 'translateX(' + x + 'px) translateY(' + y + 'px)';

					// gsap.to(icon, {
					// 	duration: 0.3,
					// 	x: x,
					// 	y: y,
					// 	each: -0.1
					// });
				});
			});*/
		}
		, setParallax : function(){
			const section = jMap.page.querySelector(jMap.section01);
			const imgs  = section.querySelectorAll('.intro-img .deco');
			const AR01 = ["500", "-500", "500", "-500", "500", "100%", "-100%", "100%", "100%", "-100%", "-100%", "-100%", "-100%"];
			const AR02 = ["300", "300", "300", "300", "300", "1000", "1200", "1000", "1000", "1200", "1200", "1200", "1200"];

			if(jMap.html.getAttribute('data-ui-size') == 'desktop'){
				const bg  = section.querySelector('.parallax-bg');

				if (bg !== null) {
					bg.dataset.swiperParallax = '100%';
					bg.dataset.swiperParallaxScale = '4';
					bg.dataset.swiperParallaxOpacity = '0';
					bg.dataset.swiperParallaxDuration = '1600';
				}
			}
			
			imgs.forEach((item, i) => {
				item.dataset.swiperParallax = AR01[i];
				item.dataset.swiperParallaxDuration = AR02[i];
			});
		}
		
		, clearParallax : function(){
			const section = jMap.page.querySelector(jMap.section01);
			const imgs  = section.querySelectorAll('.intro-img .deco');

			if(jMap.html.getAttribute('data-ui-size') == 'desktop'){
				const bg  = section.querySelector('.parallax-bg');

				if (bg !== null) {
					bg.removeAttribute('data-swiper-parallax');
					bg.removeAttribute('data-swiper-parallax-scale');
					bg.removeAttribute('data-swiper-parallax-opacity');
					bg.removeAttribute('data-swiper-parallax-duration');
				}
			}

			imgs.forEach(function(item){
				item.removeAttribute('data-swiper-parallax');
				item.removeAttribute('data-swiper-parallax-duration');
			})
		}
	}
	, section02 = {
		// init : function(){
		// 	const $section = jMap.page.querySelector(jMap.section02);
		// 	const $governing = $section.querySelector('.governing');
		// 	const $p = $governing.querySelectorAll('.main p');
		// 	const $bg = $section.querySelector('.bg');
		// 	gsap.set($bg, {top: 0, width: '100vw', height: '100vh', borderRadius: 0, ease: "power3.inOut"})
		// 	tl.section02 = gsap.timeline({paused:true})
		// 		.to($section, { delay:0.1,
		// 			onComplete: () => UI_Matter.play()
		// 		})
		// 		.from($p, {y: '6rem', autoAlpha:0, ease: 'slow', stagger:{each: 0.3, amount: 0.5,}, }, "+=1.5")
		// 	;
		// }
		keyTimer: null,
		keyboard : function(){
			const keyb = document.querySelector(".keyboard");
			keyb.classList.remove('on');
			const text = keyb.querySelector(".text");
			text.innerHTML = '';
			keyb.classList.add('on');
			const content = "오늘을 챙겨주는 건강 챌린지부터 내일을 지켜주는 건강검진까지";
			let i = 0;
			let txt = '';
			clearInterval(section02.keyTimer)
			const typing = () => {
				if (i < content.length) {
					txt = content.charAt(i);
					text.innerHTML += txt;
					i++;
					if(i == content.length){
						keyb.querySelector('.blink').style.display ='none';
					}
				}
				
			}
			section02.keyTimer = setInterval(typing, 70);
		},
		keyboardOff: function(){
			const keyb2 = document.querySelector(".keyboard");
			const text2 = keyb2.querySelector(".text");
			text2.innerHTML = '';
			keyb2.classList.remove('on');
		}

	}
	, section03 = {
		init : function(){
			// const $section = jMap.page.querySelector(jMap[_section]);
			// const $iconArea = $section.querySelector('.icon-area');
			// const $iconHand = $section.querySelector('.icon01');
			// const $iconSecond = $section.querySelector('.icon02');
			// const $aniTxt = $section.querySelector('.aniTxt');
			// const $baseTxt = $aniTxt.querySelectorAll('.base-txt');
			// const $motionTxt = $aniTxt.querySelector('.motion-txt');
			// const $chars = $motionTxt.querySelectorAll('.char');
			// const txtProps = (jMap.html.getAttribute('data-ui-size') == 'mobile')
			// 	? { y: 0, opacity: 1, ease:'slow', stagger: {each:0.1}}
			// 	: { y: 0, opacity: 1}
			// ;
			// let iconProps01 = {rotationY:-120, autoAlpha: 0}
			// let iconProps02 = {rotationY:120, autoAlpha: 0}
			
			// gsap.set($iconArea, {transformPerspective:50});
			// gsap.set($iconHand, {autoAlpha:1, transformOrigin:'50% 50% -50%'});
			// gsap.set($iconSecond, {autoAlpha:1, transformOrigin:'50% 50% -50%'});
			// gsap.set($baseTxt, { y: '6rem', opacity:0});
			// gsap.set($aniTxt, {y:0});

			// if(_section == 'section0302') {
			// 	iconProps01 = {rotationX:-90, autoAlpha: 0}
			// 	iconProps02 = {rotationX:90, autoAlpha: 0}
			// }
			// if(_section == 'section0303'){
			// 	iconProps01 = {rotationY:120, autoAlpha: 0}
			// 	iconProps02 = {rotationY:-120, autoAlpha: 0}
			// }
			// if(_section == 'section0304'){
			// 	iconProps01 = {rotationX:90, autoAlpha: 0}
			// 	iconProps02 = {rotationX:-90, autoAlpha: 0}
			// }

			// tl[_section] = gsap.timeline({paused: true , defaults:{ease: 'power3.inOut', duration: 0.3 }})
			// 	.from($iconHand, {scale: 8, y: '20rem', duration: 0.4, ease: 'slow'})
			// 	.to($iconHand, {scale: 1, duration: 0.2, ease: 'power3.elastic'})
			// 	.to($baseTxt, txtProps, '<')
			// 	.from($chars , {opacity:0, width: 0, height:0, x: '5rem', duration: 0.4, stagger:{each: 0.05, amount: $chars.length * 0.02 }}, '-=10%')
			// 	.to($section , {
			// 		onComplete: () => vMap.this.playLottieAnimation($section.getAttribute('data-section'))
			// 	}, '<')
			// 	.from($iconSecond, iconProps01 ,'-=70%')//icon
			// 	.to($iconHand, iconProps02,'-=.4')
			// ;
		},
		

	}
	, section04 = {
		init : function(){
			const $section = jMap.page.querySelector(jMap.section04);
			tl.section04 = gsap.timeline({paused: true
				, onStart() {
					motion.governing(4);
					ani.mission.play();
				}
			}).to($section, {});
		}
	}
	, section0402 = { //7
		init : function(){
			ani.stamp.progress(0);
			ani.stamp.play();
		}
	}
	, section0403 = {
		init : function(){
			ani.magic.progress(0);
			ani.magic.play();
		}
	}
	, section06 = {
		init : function(){
			const $section = jMap.page.querySelector(jMap.section06);
			tl.section06 = gsap.timeline({paused: true})
				.to($section, {
					onEnter: () => motion.governing(6, ani_rutine_tl)
				})
			;

			const $center = jMap.page.querySelector('.routine-center');
			const $swiperRutine = jMap.page.querySelector('.swiperRoutine');
			const $items = $swiperRutine.querySelectorAll('.swiper-slide');
			let posY = 150;

			gsap.set($center, {autoAlpha: 0, y:0, scale:0.8})
			gsap.set($items, {autoAlpha: 0, y: posY});
			Array.from($items).filter((item, index) => index % 2 === 0).forEach(item => gsap.set(item, { y: -posY }));

			function ani_rutine_tl(){
				gsap.timeline()
					.to($items, {autoAlpha:1, duration: 1, ease: 'power3.inOut', stagger: {each: 0.03,  amout: 1.5, y: 0} })
					.to($center, {autoAlpha:1, scale: 1, y: 0, duration: 0.8 , ease: 'power3.inOut'}, '-=0.8')
				;
			}
		}
	}
	, sectionBody = {
		init : function(){
			//section07
			const section07 = jMap.page.querySelector(jMap.section07);
			tl.section07 = gsap.timeline({paused: true})
				.to(section07, {
					onEnter: () => motion.governing(7, ani_card)
				})
			;

			const $program = jMap.page.querySelector('.program-list');
			const lists = $program.querySelectorAll('li');
			gsap.set(lists, {autoAlpha:0});

			function ani_card(){
				lists.forEach((item) => {
					const tl_program = gsap.timeline();
					gsap.set(item, {autoAlpha: 1})
					ScrollTrigger.create({
						trigger: item,
						scroller: '.bodySection',
						start: "+=8rem 80%",
						animation : tl_program.from(item, {autoAlpha:0, y: '10rem'})
						/*markers: true,*/
					});
				});

				//section08
				const section08 = jMap.page.querySelector(jMap.section08);
				const txts = section08.querySelectorAll('.main p');
				const btns = section08.querySelector('.btn-area');
				const qrWrap = section08.querySelector('.qr-wrap');
				const qrCode = section08.querySelector('.qr-code img');
				const qrDeco = section08.querySelector('.qr-code .deco');
				gsap.set(section08, {autoAlpha:0, y:'80%'});
				gsap.timeline({
						scrollTrigger : {
							trigger : section08,
							scroller: '.bodySection',
							// markers: true,
						}
					})
					.to(section08, {autoAlpha: 1, y:0})
					.from(txts, {autoAlpha: 0, y: '5rem', stagger:{each:0.2}})
					.from(btns, {autoAlpha: 0, y: '5rem'}, '-=.2')
					.from(qrWrap, {autoAlpha: 0, rotate:'300deg', scale:0.8, x: '30rem', y: '-10rem', ease: 'back'}, '-=.2')
					.from(qrDeco, {autoAlpha: 0, scale:2, ease: 'back'}, )
					.from(qrCode, {autoAlpha: 0, }, '<')
				;
			}
		}
	}
	, motion = {
		mission : function(){
			const $section = jMap.page.querySelector(jMap.section04);
			const $panel = $section.querySelector('.panel')// + ' .panel';
			gsap.set($panel, {autoAlpha:1, left: '50%'});
			ani.mission = gsap.timeline({paused:true})
				.from($panel, {autoAlpha:0, left: '100%', duration: 0.6, ease: 'expo'})
			;

			//marquee
			const marquee01 = jMap.page.querySelector('.mission-content01');
			const marqueeContent01 = marquee01.querySelector('.scroll-wrap');
			const marqueeContentClone01 = marqueeContent01.cloneNode(true);
			marquee01.appendChild(marqueeContentClone01);

			const marquee02 = jMap.page.querySelector('.mission-content02');
			const marqueeContent02 = marquee02.querySelector('.scroll-wrap');
			const marqueeContentClone02 = marqueeContent02.cloneNode(true);
			marquee02.appendChild(marqueeContentClone02);

			const size = marquee01.querySelector('.scroll-wrap').offsetHeight;
			const gap = 32;
			const pos = gap + size;

			ani.mission_marquee = gsap.timeline({paused:true})
				.fromTo(marquee01.children, {y: 0}, {y: pos * -1, duration: 20, ease: "none", repeat: -1} )
				.fromTo(marquee02.children, {y: 0}, {y: pos, duration: 20, ease: "none", repeat: -1} , '<')
			;
		}
		, stamp : function(){
			const stampWrap = jMap.page.querySelector('.stamp'); 
			const stampBox = stampWrap.querySelector('.stamp-ani');
			const stampImg = stampWrap.querySelector('.stamp-img');
			const stampTxt = stampWrap.querySelector('.stamp-txt');
			gsap.set(stampWrap, { autoAlpha:0, y: '60rem' })
			gsap.set(stampBox, { autoAlpha:0, y: '60rem' })
			gsap.set(stampImg, { autoAlpha:1, scale: 1.3, rotation:45})
			ani.stamp = gsap.timeline({ paused: true})
				.to(stampWrap, { autoAlpha:1, y:0, ease:'power3.inOut'})
				.to(stampBox, { autoAlpha:1, y:0, ease:'power3.inOut'}, '<')
				.to(stampImg, { scale: 0.8, ease:'power4', duration:0.3})
				.to(stampImg, { scale: 1.05, ease:'back', rotation:0})
				.to(stampTxt, { color: '#000', duration:0.2}, '<')
			;
		}
		, magic : function(){
			const $magic = jMap.page.querySelector('.ani-magic');
			const $gifts = $magic.querySelectorAll('.gifts span');
			const $hand = $magic.querySelector('.magic-hand');
			const $ticket = $magic.querySelector('.magic-ticket');
			const $deco = $magic.querySelector('.magic-deco');
			ani.magic = gsap.timeline({ paused: true})
				.from($gifts, {autoAlpha: 0 , y: '-30rem', ease: 'back', duration: 0.6 , stagger:{each: 0.08 , from: 'center'} })
				.from($hand, {autoAlpha:0, y:'10rem', ease: 'back'}, '-=50%')
				.fromTo($ticket, {autoAlpha:0, x:-50, y:-50, rotation :0 }, {autoAlpha:1, x:0, y:0, duration:.2, rotation :68 })
				.from($deco, {autoAlpha:0, scale:0.8, ease: 'back'})
			;
		}
		, tazagi : function(){
			const $tazagi = '.ani-tazagi';
			const $icon = $tazagi + ' i';
			const $p = $tazagi + ' p';
			gsap.set($tazagi, {autoAlpha:1})
			gsap.set($p, {color:'#000'})
			gsap.set($p + ' span', {color:'#000'})
			ani.tazagi = gsap.timeline({paused:true})
				.from($p, {autoAlpha:0 , y: '10rem', duration:0.8 , ease:'power3.inOut', stagger:{each: 0.2 } }, '+=1')
				.from($icon, {autoAlpha:0 , scale: 0 , width: 0 , stagger:{each: 0.5 , amount: 0.5}, ease:'power3.inOut'})
				.to($p, {color: '#E5E5E5', stagger:{each: 0.3 } }, '<')
				.to($p + ' span', {color: '#000', stagger:{each: 0.5 } }, '<')
			;
		}
		, governing : function(_targetNum, _callBack){
			const targetNum = _targetNum;
			const $section = jMap.page.querySelector(jMap['section0'+ targetNum]);
			const $governing = $section.querySelector('.governing');
			const $round = $governing.querySelector('.icon-title .round');
			const $txt = $governing.querySelector('.icon-title .gtxt');
			const $all = jMap.page.querySelectorAll(jMap['section0'+ targetNum] + ' .governing > *');
			gsap.set($governing, {autoAlpha:1});
			ani['governing0' + targetNum] = gsap.timeline({paused: false
					, onComplete(){
						if(_callBack) _callBack()
					}
				})
				.from($all, {autoAlpha:0, y: '20rem', stagger:{each: 0.12}})
				.from($round, {x: '25%', duration:0.4, ease:'power3.inOut'}, '-=70%')
				.from($txt, {x: '-75%', duration:0.4, ease:'power3.inOut'}, '<')
			;
		}
		, changeTitle : function(index){
			const section04 = jMap.page.querySelector(jMap.section04);
			const lis = section04.querySelectorAll('.governing .desc li');

			lis.forEach((li, i) => li.classList.toggle('on', i === index));

			const tit = section04.querySelector('.governing .icon-title');
			const em  = tit.querySelector('.round em');
			const icon = tit.querySelector('.round .icon');
			if(index === 0){
				em.innerText = '쉽고 편하게';
				icon.className = 'icon calendar';
			}else if(index === 1){
				em.innerText = '똑똑하게';
				icon.className = 'icon calendar2';
			}else if(index === 2){
				em.innerText = '즐겁게';
				icon.className = 'icon calendar';
			}
		}
		, pdf : function(){
			const $pdf = jMap.page.querySelector('.pdf');
			const $txt = jMap.page.querySelector('.pdf-txt');
			const pdfLinks = $pdf.querySelector('a');

			gsap.set($pdf, {autoAlpha:1})
			ani.pdf = gsap.timeline({paused:false})
				.to($txt, {rotate:"360", ease:'none'})
			;

			pdfLinks.addEventListener('focusin', onHandler);
			pdfLinks.addEventListener('mouseenter', onHandler);
			pdfLinks.addEventListener('focusout', offHandler);
			pdfLinks.addEventListener('mouseleave', offHandler);
			pdfLinks.addEventListener('click', offHandler);

			function onHandler(){
				ani.pdf.pause();
				gsap.to($pdf, {scale:'1.16', duration:0.2, ease:'power3.inOut'})
				gsap.to($txt, {rotate:"180"});
			}

			function offHandler(){
				ani.pdf.play();
				gsap.to($pdf, {scale:'1', duration:0.3, ease:'back'})
				gsap.to($txt, {rotate:"360"});
			}
		}
		, arrow : function(){
			const $arrow = jMap.page.querySelector('[data-section="01"] .arrow');
			ani.arrow = gsap.timeline({paused:true})
				.fromTo($arrow, 
					{y: -5, autoAlpha:1}, 
					{y:10, duration: 0.8, ease: 'slow', repeat: -1, yoyo:true}
				)
			;
		}
	}
	, clear = function(){
		UI_Matter.clear();
		tl.section01.kill();
		// tl.section02.kill();
		// tl.section03.kill();
		// tl.section0302.kill();
		// tl.section0303.kill();
		// tl.section0304.kill();
		tl.section04.kill();
		// tl.section05.kill();
		// tl.section0502.kill();
		tl.section06.kill();
		tl.section07.kill();
		ani.mission.kill();
		ani.mission_marquee.kill();
		ani.stamp.kill();
		ani.magic.kill();
		ani.tazagi.kill();
		ani.pdf.kill();
		ani.arrow.kill();
	}
	, init = function(_vueTarget){
		setMap(_vueTarget);

		//모바일 기기 주소창에 따른 높이 대응 (100vh)
		function setConH(){
			const setHeight = objs => objs.forEach(obj => obj.style.height = document.body.clientHeight + 'px');
			setHeight(jMap.page.querySelectorAll('.swiperSection'));
			setHeight(jMap.page.querySelectorAll('.comp'));
		}

		setTimeout(function(){
			window.addEventListener('resize', setConH);
			setConH();

			document.body.scrollTop = 0;
			jMap.bodySection.scrollTop = 0;

			section01.init();
			section03.init();
			// section03.init('section0302');
			// section03.init('section0303');
			// section03.init('section0304');
			section04.init();
			// section05.init();
			// section0502.init();
			section06.init();
			sectionBody.init();
			motion.mission();
			motion.stamp();
			motion.magic();
			motion.tazagi();
			motion.pdf();
			motion.arrow();

			swipe.init();

			//태블릿 이하 페이지 셋팅
			if(jMap.html.getAttribute('data-ui-size') != 'desktop' || !jMap.html.classList.contains('desktop')){
				const bg = document.querySelector('.parallax-bg');
				if(bg) bg.parentNode.removeChild(bg);
			}

			//테스트 모드: 초기 swiper
			if (UI.Pub.testFlag) vMap.this.slideTo(vMap.startSection);
		}, 100)
	};

	return {
		init : init
		, clear : clear
		, change: swipe.change
	}
})();



const UI_Matter = (function(){
	const Engine = Matter.Engine,
			Render = Matter.Render,
			Runner = Matter.Runner,
			Composite = Matter.Composite,
			Bodies = Matter.Bodies;

	const engine = Engine.create(),
			world = engine.world;

	let render;
	let runner;

	let conW = window.innerWidth;
	let conH = window.innerHeight;

	const clear = function(){
		const canvasWrap = document.querySelectorAll('.drop canvas');
		if(canvasWrap.length){
			Matter.Render.stop(render);
			Matter.World.clear(world);
			Matter.Engine.clear(engine);
			Matter.Runner.stop(runner);
			render.canvas.remove();
			render.canvas = null;
			render.context = null;
			render.textures = {};
		}
	}

	const play = function(){
		conW = window.innerWidth;
		conH = window.innerHeight;

		render = Render.create({
			element: document.getElementById('canvas'),
			engine: engine,
			options: {
				width: conW,
				height: conH,
				pixelRatio: 1,
				background: 'transparent',
				wireframes: false,
				showVelocity: false
			}
		});

		Render.run(render);
		runner = Runner.create();
		Runner.run(runner, engine);

		//walls
		const wallBg = { fillStyle: 'transparent' };
		const wallTop = -180;
		Composite.add(world, [
			Bodies.rectangle( 0, (conH / 2), 100, conH, { render: wallBg,  isStatic: true }),//left
			Bodies.rectangle((conW / 2) + 160, wallTop, conW + 320, 160, { render: wallBg, isStatic: true }), //top
			Bodies.rectangle((conW), conH / 2, 120, conH, { render: wallBg, isStatic: true }), //right
			Bodies.rectangle((conW / 2), conH , conW, 10, { render: wallBg, isStatic: true }),//bottom
		]);

		const chamfer = { radius: 1 };
		let scale =  1;
		if(window.innerWidth < 1200) scale =0.8
		if(window.innerWidth < 960) scale =0.6
		if(window.innerWidth < 768) scale =0.4
		if(window.innerWidth < 360) scale =0.3

		function ranNum(_width){
			let ranNum = Math.floor(Math.random() * (conW - _width));
			if(ranNum < _width) ranNum  = conW - _width 
			return ranNum;
		}

		const canvasImg = document.querySelector('.canvas-img')
			, anywhere = canvasImg.querySelector('.anywhere').src
			, benefit = canvasImg.querySelector('.benefit').src
			, challenge = canvasImg.querySelector('.challenge').src
			, deco1 = canvasImg.querySelector('.deco1').src
			, deco2 = canvasImg.querySelector('.deco2').src
			, deco3 = canvasImg.querySelector('.deco3').src
			, exercise = canvasImg.querySelector('.exercise').src
			, health = canvasImg.querySelector('.health').src
			, help = canvasImg.querySelector('.help').src
			, mentality = canvasImg.querySelector('.mentality').src
			, planner = canvasImg.querySelector('.planner').src
			, routine = canvasImg.querySelector('.routine').src
		;

		Composite.add(world, [
			Bodies.rectangle(ranNum(256 * scale), wallTop, (256 * scale), (96 * scale),
				{ chamfer: chamfer, render: { sprite: { texture: benefit, xScale: scale, yScale: scale }}}),
			Bodies.rectangle(ranNum(282 * scale), wallTop, (282 * scale), (96 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: routine, xScale: scale, yScale: scale }}}),
			Bodies.rectangle(ranNum(334 * scale), wallTop, (334 * scale), (96 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: anywhere, xScale: scale, yScale: scale }}}),
			Bodies.rectangle(ranNum(340 * scale), wallTop, (340 * scale), (96 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: challenge, xScale: scale, yScale: scale }}}),
			Bodies.rectangle(ranNum(280 * scale), wallTop, (280 * scale), (96 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: exercise, xScale: scale, yScale: scale }}}),
			Bodies.rectangle(ranNum(255 * scale), wallTop, (255 * scale), (96 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: health, xScale: scale, yScale: scale }}}),
			Bodies.rectangle(ranNum(183 * scale), wallTop, (183 * scale), (96 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: help, xScale: scale, yScale: scale }}}),
			Bodies.rectangle(ranNum(340 * scale), wallTop, (340 * scale), (96 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: mentality, xScale: scale, yScale: scale }}}),
			Bodies.rectangle(ranNum(295 * scale), wallTop, (295 * scale), (96 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: planner, xScale: scale, yScale: scale }}}),
			//deco
			Bodies.rectangle(ranNum(105 * scale), wallTop, (105 * scale), (105 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: deco1, xScale: scale, yScale: scale }}}),
			Bodies.rectangle(ranNum(95 * scale), wallTop, (95 * scale), (95 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: deco2, xScale: scale, yScale: scale }}}),//green rect
			Bodies.rectangle(ranNum(95 * scale), wallTop, (95 * scale), (95 * scale), 
				{ chamfer: chamfer, render: { sprite: { texture: deco3, xScale: scale, yScale: scale }}}) ,//red
		]);
	}

	return {
		play : play
		, clear : clear
	}
})();

export {UI_Ocare}