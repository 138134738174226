import LayoutMain from "@/layout/LayoutMain.vue";

const main = {
  path: "",
  name: "Main",
  component: LayoutMain,
  meta: {
    requiresAuth: false,
    title: "국민과 건강사이 KB헬스케어", 
  },
  children: [
    {
      path: "",
      name: "MainPage",
      component: () => import("@/views/main/MainPage.vue"),
    },
  ],
};
export default main;
