export const name = "ui.common";

import { useRoute } from "vue-router";
import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const UI = (function(){
	let jMap = {}
	let vMap = {}
	, setMap = function(){
		jMap = {
			html: document.querySelector('html')
			, body: document.querySelector('body')
			, nav: document.querySelector('.nav')
			, gnb: document.querySelector('#gnb')
			, wrap: document.querySelector('#app')
			, container: document.querySelector('#container')
			, header: document.querySelector('.header ')
			, content: document.querySelector('#content')
			, contentWrap: document.querySelector('#content .content-wrap')
			, footer: document.querySelector('.footer')
		}
		, vMap ={
			movieNum: -99
			, prevNum: -99
			, overFlag: -99
			, prevOverFlag: -99
			, browser: {
				size: 'lg'
				, oldSize: ''
				, breakPoint: [768, 1200, 1920]
				, chage: false
			}
			, gnb:{
				timeline: null
				, prevWidth: 0
				, prevLeft: 0
			}
		}
	}
	, Pub = {
		testFlag: false
		, version: "0926.0001"
		, pageLoadCnt: 0
		, is_firstLoad: true
		, test: {
			init: function(){
				if(!Pub.testFlag) return;
				jMap.html.classList.add('test');
				const divElement = document.createElement('div');
				divElement.className = 'test-label';
				jMap.html.insertBefore(divElement, jMap.html.firstChild);
			}
			, insert: function(con){
				const label = document.querySelector('.test-label');
				const span = label.querySelector('span');
				if(span) span.parentNode.removeChild(span);

				const newSpan = document.createElement('span');
				newSpan.textContent = con;
				label.appendChild(newSpan);
			}
		}
		, setVersion: function(){
			jMap.html.setAttribute('data-version', Pub.version)
			Pub.test.init();
		}
	}
	, util = {
		isValid: function(variables) {
			if (variables == null || variables == undefined || variables === '' || variables == 'undefine') return false;
			else return true;
		}
		, browserCheck: function(){
			const userAgent = navigator.userAgent
				, $elem = document.querySelector('html')
				, isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent)
				, isTablet = /iPad/i.test(userAgent)
				, isDesktop = !isMobile && !isTablet
				, isIOS = (/ip(ad|hone|od)/i).test(userAgent)
				, isAndroid = (/android/i).test(userAgent)
				, ieMode = !!document.documentMode || 0
			;
			//Device
			if(isMobile) $elem.classList.add('mobile');
			if(isTablet) $elem.classList.add('tablet');
			if(isDesktop) $elem.classList.add('desktop');
			//OS
			if(isIOS) $elem.classList.add('ios');
			if(isAndroid) $elem.classList.add('android');
			//Browser
			if(ieMode) $elem.classList.add('ie ie'+ieMode);
			if(userAgent.indexOf("Firefox") > 0) $elem.classList.add('firefox');
			else if(userAgent.indexOf("Opera") > 0) $elem.classList.add('opera');
			else if(userAgent.indexOf("Chrome") > 0) {
				$elem.classList.add('chrome');
				if(userAgent.indexOf("Whale") > 0) $elem.classList.add('whale');
				if(userAgent.indexOf("Edg") != -1) $elem.classList.add('edge');
			}
			else if(userAgent.indexOf("Safari") > 0) $elem.classList.add('safari');
		}
		, resizeCheck: function(_callback, _param){
			let intervalID
				, cnt = 0
				, tmp = 0
				, flag = false
				, delay = 300
				, timer
			;
			const check = function(){
				clearInterval(intervalID);
				intervalID = setInterval(function(){
					if(tmp == cnt){
						clearInterval(intervalID);
						cnt = tmp =0;
						jMap.html.classList.remove('resize');
						if(_callback) _callback(_param);
						clearTimeout(timer);
						timer = setTimeout(function(){
							flag = false;
							return false;
						}, delay);
					}
					tmp = cnt;
				}, delay);
			};
			window.addEventListener('resize', function(){
				vMap.browser.oldSize = vMap.browser.size; 
				cnt++;
				jMap.html.classList.add('resize');
				if(!flag) {
					flag = true;
					check();
				}
			});
		}
		, getSiblings: function(element) {
			return Array.from(element.parentElement.children).filter(child => child !== element);
		}
	}
	, browser = {
		size: function(){
			let winW = window.innerWidth;
			if (winW < vMap.browser.breakPoint[0]) vMap.browser.size = 'mobile';
			else if (winW < vMap.browser.breakPoint[1]) vMap.browser.size = 'tablet';
			else vMap.browser.size = 'desktop';
			jMap.html.setAttribute('data-ui-size', '');
			jMap.html.setAttribute('data-ui-size', vMap.browser.size);
		}
		, get: function(){
			return vMap.browser.size;
		}
	}
	, gnb = {
		start: function(){
			gnb.location();
			vMap.gnb.timeline = gsap.timeline({defaults: {duration: 0.01 , ease: 'power3.inOut'}});
			gnb.initMotion();
			gnb.handler();
		}
		, init: function(){
			jMap.nav.classList.remove('show');
			jMap.body.classList.remove('mo-nav-show');

			gnb.location();
			gnb.desktopUnderLine();
		}
		, location: function(){
			if(vMap.movieNum == -99) return;
			vMap.overFlag = vMap.movieNum;

			const lis = jMap.gnb.querySelectorAll('li');
			const curPage = lis[vMap.movieNum];
			const siblings = util.getSiblings(curPage);
			const link = curPage.querySelector('a');

			curPage.classList.add('on');
			siblings.forEach(sibling => sibling.classList.remove('on'));
			link.setAttribute('title', '');
		}
		, handler: function(){
			jMap.gnb.removeEventListener('focusout', desktopHandler);
			jMap.gnb.removeEventListener('mouseleave', desktopHandler);
			jMap.gnb.addEventListener('focusout', desktopHandler);
			jMap.gnb.addEventListener('mouseleave', desktopHandler);

			const $navItem = jMap.gnb.querySelectorAll('li');
			$navItem.forEach((navItem) => {
				const link = navItem.querySelector('a');
				link.removeEventListener('focusin', desktopHandler);
				link.removeEventListener('mouseenter', desktopHandler);
				link.addEventListener('focusin', desktopHandler);
				link.addEventListener('mouseenter', desktopHandler);
				link.addEventListener('click', mobileCloseHandler);//mobile 메뉴에서 현재 메뉴 클릭시 닫힘
			});

			function desktopHandler(event) {
				if(vMap.browser.size != 'desktop') return;
				if (event.type === 'focusin' || event.type === 'mouseenter') {
					if (event.currentTarget.tagName === 'A'){
						vMap.overFlag = Array.from(event.currentTarget.parentNode.parentNode.children).indexOf(event.currentTarget.parentNode);
						vMap.prevOverFlag = vMap.overFlag;
					}
				}else if (event.type === 'focusout' || event.type === 'mouseleave') {
					vMap.overFlag = vMap.movieNum;
				}
				gnb.desktopUnderLine();
			}

			//mobile
			document.querySelector('.btn-menu').removeEventListener('click', mobileOpenHandler);
			document.querySelector('.btn-menu').addEventListener('click', mobileOpenHandler);
			document.querySelector('.btn-close').removeEventListener('click', mobileCloseHandler);
			document.querySelector('.btn-close').addEventListener('click', mobileCloseHandler);

			function mobileOpenHandler(event){
				event.preventDefault();
				jMap.body.classList.add('mo-nav-show');
				jMap.nav.classList.add('show');
				gsap.to(jMap.nav, {opacity:1});
				gnb.initMotion();
			}

			function mobileCloseHandler(event){
				if(vMap.browser.size == 'desktop') return;

				if (event.currentTarget.getAttribute('aria-current') !== null) {
					event.preventDefault();
				}
				
				gsap.to(jMap.nav, {duration:0.2, opacity:0, 
					onComplete(){
						jMap.nav.removeAttribute('style');
						jMap.nav.classList.remove('show');
					}
				})
				jMap.body.classList.remove('mo-nav-show');
			}
		}
		, initMotion: function(){
			const $lis = jMap.gnb.querySelectorAll('li');
			gsap.set($lis, {autoAlpha:0, y: 15});

			if(vMap.browser.size == 'desktop'){
				const underline = document.querySelector('[data-ui-size="desktop"] .nav-underline');
				gsap.set(underline, {autoAlpha:0});
				gsap.timeline()
					.to($lis, {autoAlpha: 1 , delay: 0.4 , ease: 'power3.inOut', stagger:{each: 0.1 , y: 0 } })
					.to(underline, {autoAlpha: 1,
						onComplete: () => gnb.desktopUnderLine()
					}, '<')
				;
			}else{
				gsap.to($lis, {autoAlpha: 1 , ease: 'power3.inOut', stagger:{each: 0.1 , y: 0 } });
			}
		}
		, desktopUnderLine: function(){
			if(vMap.browser.size != 'desktop') return;
			const underline = document.querySelector('.nav-underline');
			
			//메인 또는 메뉴 외 클릭시 line Hide
			if(vMap.overFlag == -99) {
				let goNum = vMap.prevOverFlag; 
				if(vMap.overFlag != -99) goNum = vMap.overFlag;
				if(vMap.prevOverFlag == -99) goNum = 0;
				vMap.gnb.prevLeft = jMap.gnb.querySelectorAll('li')[goNum].getBoundingClientRect().left; //사라질떄
				if(vMap.prevOverFlag == -99) vMap.gnb.prevLeft = vMap.gnb.prevLeft + 80;//초기 나타날때
				vMap.gnb.timeline .to(underline, {width: 0 , left: vMap.gnb.prevLeft + (vMap.gnb.prevWidth / 2) });
				return;
			}

			const goItem = jMap.gnb.querySelectorAll('li')[vMap.overFlag];
			let left = goItem.getBoundingClientRect().left;
			let width = goItem.offsetWidth;

			vMap.gnb.prevWidth = width;
			vMap.gnb.prevLeft = left;

			gsap.set(underline, {width: width - 120, left: left + 60, autoAlpha:0});
			vMap.gnb.timeline
				.to(underline, {
					left: left + 40
					, autoAlpha: 1
					, width: width - 80
					, immediateRender: true
					, onCompleted(){
						vMap.prevOverFlag = vMap.overFlag;
						underline.classList.add('start');
					}
				})
			;
		}
	}
	, accordion = {
		handler : function(_target, _openNum){
			const itemClassName = '.acco-item';
			const itemHeadClassName = '.acco-btn';
			const itemBodyClassName = '.acco-contents';
			const activeClassName = 'active';

			const target = document.querySelector(_target);
			const itemElements = target.querySelectorAll(itemClassName);
			
			//init
			if(target.classList.contains('loadOn')) itemElements.forEach(closeHandler); //재로드
			else target.classList.add('loadOn'); //초기
			
			itemElements.forEach(function(item, i) {
				const head = item.querySelector(itemHeadClassName);

				if (_openNum === i 
					|| item.parentElement.classList.contains(activeClassName) 
					|| item.classList.contains(activeClassName)
				) openHandler(item);
				else closeHandler(item);

				head.removeEventListener('click', toggleAccordion); 
				head.addEventListener('click', toggleAccordion);
			});

			function toggleAccordion(e) {
				e.preventDefault();
				const item = this.closest(itemClassName);
				if (item.classList.contains('disabled') || this.classList.contains('disabled')) return false;

				if (!item.classList.contains('active')) openHandler(item, e);
				else closeHandler(item);

				//하나만 열리고 모두 닫힘
				util.getSiblings(item).forEach(closeHandler);

				return false;
			}

			function openHandler(item, e){
				let isButton = e ? true : false;
				const head = item.querySelector(itemHeadClassName);
				const body = item.querySelector(itemBodyClassName);

				item.classList.add(activeClassName);
				head.classList.add(activeClassName);
				head.setAttribute('title', '내용닫기');
				head.setAttribute('aria-expanded', true);

				gsap.timeline()
					.to(body, {display:'block', overflow:'hidden',  ease: 'power3.inOut', height:body.scrollHeight
						, onComplete(){
							//리사이즈시 버그 제제
							gsap.set(this.targets(), { clearProps: "all" });
							//클릭시만 상단 스크롤 이동
							if (isButton){
								gsap.to(window, {ease: 'power3.inOut'
									, scrollTo: (item.getBoundingClientRect().top + window.scrollY - jMap.header.offsetHeight + 1)
								});
							}
						}
					})
				;
			}

			function closeHandler(item){
				if(item.classList.contains('no-data')) return;
				item.classList.remove(activeClassName);
				const head = item.querySelector(itemHeadClassName);
				const body = item.querySelector(itemBodyClassName);

				head.classList.remove(activeClassName);
				head.setAttribute('title', '내용보기');
				head.setAttribute('aria-expanded', false);

				body.style.transition = 'height 0.16s';
				body.style.overflow = 'hidden';
				body.style.height = '0';
			}
		}
	}
	, page = {
		init: function(){
			Pub.is_firstLoad = false;
			if(jMap.html.classList.contains('onLoad')) {
				jMap.html.classList.remove('cpLoad'+Pub.pageLoadCnt);
				Pub.pageLoadCnt ++;
				jMap.html.classList.add('cpLoad'+Pub.pageLoadCnt);
			} else {
				jMap.html.classList.add('onLoad');
				util.browserCheck();
			}
		}
		, update: function(){
			const route = useRoute();
			const menu1Depth = route.matched[1].path;
			const pageName = route.name;
			// console.log(`menu1Depth: ${menu1Depth}, pageName: ${route.name}`);

			//init
			vMap.movieNum = -99;
			vMap.overFlag = -99;

			jMap.body.classList.remove("ov-h");
			jMap.header.classList.add("show-bg");
			console.log('menu1Depth-------',menu1Depth);
			//set
			if(menu1Depth === '/') {
				jMap.body.classList.add("ov-h");
				jMap.header.classList.remove("show-bg");
				
			}else if(menu1Depth === '/ocare') {
				jMap.body.classList.add("ov-h");
				vMap.movieNum = 0;
			}else if(menu1Depth === '/recruit'){
				vMap.movieNum = 1;
			}else if(menu1Depth === '/news'){
				vMap.movieNum = 2;
				//Detail Page는 확인 필요
				(pageName === 'PressDetail' || pageName === 'NoticeDetail' || pageName === 'BlogDetail') ? motion.init() : motion.notice(); 
			}else if(menu1Depth === '/terms'){
				motion.notice();
			}
			// else if(menu1Depth === '/error'){}

			vMap.prevNum = vMap.movieNum;
		}
	}
	, motion = {
		init: function(){
			const target = document.querySelector('.content-wrap > .section > .comp');
			gsap.set(target, {autoAlpha:0});
			setTimeout(motion.common, 300);
		}
		, common: function(){
			const target = document.querySelector('.content-wrap > .section > .comp');
			const items = Array.from(target.children);

			gsap.set(items, {autoAlpha:0, y: '6rem'});
			gsap.timeline()
				.to(target, {autoAlpha:1
					, onComplete(){gsap.set(this.targets(), { clearProps: "all" }); }
				})
				.to(items, {autoAlpha:1, y:0 , stagger:{each: 0.3}
					, onComplete(){gsap.set(this.targets(), { clearProps: "all" }); }
				})
			;
		}
		//0908: 현재 사용 안함
		, notice: function(){
			if(vMap.prevNum !== vMap.movieNum){//소식 처음 로드
				motion.init();
			}else{//소식 재 로드: 탭클릭시
				const tabCon = document.querySelector('.tab-content');
				gsap.set(tabCon, {autoAlpha:0});
				gsap.timeline()
					.to(tabCon, {autoAlpha:1
						, onComplete(){gsap.set(this.targets(), { clearProps: "all" }); }
					})
				;
			}
		}
	}
	, init = function(){
		document.documentElement.scrollTop = 0;
		// gsap.to(window, {ease: 'power3.inOut', scrollTo: 0 });

		//공통 & 최초 로드시...
		if(Pub.is_firstLoad){
			setMap();
			browser.size();
			util.resizeCheck(browser.size);
			util.resizeCheck(gnb.desktopUnderLine);
			Pub.setVersion();
			page.init();
			page.update();
			gnb.start();
		}
		//페이지 로드할때마다...
		else{
			browser.size();
			page.init();
			page.update();
			gnb.init();
		}
	};

	return {
		init: init
		, Pub: Pub
		, accordion: accordion.handler
	}
})();

export { UI }