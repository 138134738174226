<script setup>
import { useRoute } from "vue-router";

const route = useRoute();
const _healthlink = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? `${process.env.VUE_APP_LINK_HEALTHCHECK}/mobile` : `${process.env.VUE_APP_LINK_HEALTHCHECK}`;



console.log('healthlink', _healthlink);

</script>

<template>
  <header class="header">
    <div class="header-wrap">
      <router-link :to="{ path: '/' }" class="btn-home">
        <h1><span>KB 헬스케어</span></h1>
      </router-link>
      <button type="button" class="btn-menu">
        <span>모바일 메뉴 열기</span>
      </button>
      <button type="button" class="btn-close">
        <span>모바일 메뉴 닫기</span>
      </button>
      <div class="nav">
        <div class="nav-wrap">
          <div class="nav-bg"></div>
          <div class="nav-underline"></div>
          <ul id="gnb">
            <li v-for="(row, idx) in route.meta.menu1Depth" :key="idx">
              <router-link :to="row.link"><span>{{ row.title }}</span></router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<style></style>
