<template>
  <div id="container" data-ui-page="ocare">
    <div id="content" class="content">
      <div class="content-wrap">
        <!-- swiperSection -->
        <div class="swiper swiperSection ocareSlider">
          <swiper
            class="swiper-wrapper"
            :direction="'vertical'"
            :slidesPerView="1"
            :mousewheel="true"
            :pagination="{ clickable: true }"
            :parallax="true"
            :autoplay="false"
            :modules="modules"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide class="section" data-section="01">
              <div class="title-area">
                <h2 class="h2">Section 01. KB O&lsquo; CARE Intro</h2>
              </div>
              <div
                class="parallax-bg"
                data-swiper-parallax="100%"
                data-swiper-parallax-scale="4"
                data-swiper-parallax-opacity="0"
                data-swiper-parallax-duration="1600"
              ></div>
              <div class="comp">
                <div class="governing" data-swiper-parallax="300">
                  <div class="main">
                    <p class="txt-line">건강한 오늘<span>,</span></p>
                    <p class="txt-line"> 
                      오늘의
                      <span class="svg-logo"><em>O&lsquo;care</em></span>
                    </p>
                  </div>
                  <div class="sub txt-line" >내 모든 건강관리 KB 오케어와 함께하며 <br class="mo-br" /> 일상의 균형을 맞춰보세요</div>
                </div>
                <div class="intro-img">
                  <div
                    class="deco section-character01"
                    data-swiper-parallax="-100%"
                    data-swiper-parallax-duration="500"
                  >
                    <i></i>
                  </div>
                  <div
                    class="deco section-character02"
                    data-swiper-parallax="-100%"
                    data-swiper-parallax-duration="600"
                  >
                    <i></i>
                  </div>
                  <div
                    class="deco section-star01"
                    data-swiper-parallax="-100%"
                    data-swiper-parallax-duration="1200"
                  >
                    <i></i>
                  </div>
                  <div
                    class="deco section-star02"
                    data-swiper-parallax="-100%"
                    data-swiper-parallax-duration="1200"
                  >
                    <i></i>
                  </div>
                </div>
              </div>
              <span class="arrow"></span>
            </swiper-slide>

            <swiper-slide class="section" data-section="02">
              <div class="title-area">
                <h2 class="h2">Section 02. 다짐</h2>
              </div>
              <div class="comp">
                <div class="keyboard">
                  <span class="text"></span>
                  <span class="blink">|</span>
                  <em class="em">KB 오케어에서 함께.</em>
                  <div class="keyboard-img">
                    <img src="@/resources/img/ocare/m_section_charact.png" alt=""/>
                  </div>
                </div>
              </div>
              <div class="bg"></div>
            </swiper-slide>

            <swiper-slide class="section" data-section-group="03" data-section="03">
              <swiper class="video-box" @swiper="onSwiper2" @slideChange="onSlideChange2" v-bind="onSlideChange2Options"
                        :modules="modules" :navigation="true">
                <swiper-slide>
                  <div class="video-area">
                    <span># 건강검진 해석 서비스 편 </span>
                    <video src="https://kbhckrcaboutkbhealthcdn.azureedge.net/homepage/0314_KB_O_CARE_2.mp4" loop muted playsinline autoplay></video>
                    <div class="vidio-txt">
                      <strong>쉽고 자세한 검진분석은</strong>
                      <em>KB 오케어와</em>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="video-area">
                    <span># 10년 건강검진 편</span>
                    <video src="https://kbhckrcaboutkbhealthcdn.azureedge.net/homepage/0314_KB_O_CARE_3.mp4" loop muted playsinline></video>
                    <div class="vidio-txt">
                      <strong>10년치 검진결과 관리는</strong>
                      <em>KB 오케어와</em>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="video-area">
                    <span># 챌린지타임 편</span>
                    <video src="https://kbhckrcaboutkbhealthcdn.azureedge.net/homepage/0314_KB_O_CARE_1.mp4" loop muted playsinline></video>
                    <div class="vidio-txt">
                      <strong>꾸준한 건강 챌린지는</strong>
                      <em>KB 오케어와</em>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="video-m-box">
                <div class="box">
                  <div class="bg">
                    <img src="@/resources/img/ocare/m_video_bg.png" alt=""/>
                  </div>
                  <div class="youtube">
                    <a target="_blank" href="https://www.youtube.com/watch?v=ttmgEBYtkqw&list=PLZlUuGt1sVInB61hO0XnO0uAZIXxY0wP-&index=1">
                      <img src="@/resources/img/ocare/m_video.png" alt=""/>
                    </a>
                    <div class="text">
                      <span>KB 오케어와 함께하는 </span>
                      <em>우리의 하루가 궁금하면 광고보기</em>
                    </div>
                  </div>
                  <div class="tit">
                    건강관리에 대한 모든 것<br>KB 오케어와 한 번에
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide class="section" data-section-group="04" data-section="04">
              <div class="title-area">
                <h2 class="h2">Section 04. 함께 할게요</h2>
              </div>
              <div class="comp fixed-comp">
                <div class="governing">
                  <h3 class="icon-title">
                    <span class="round"><i class="icon calendar"></i><em>쉽고 빠르게</em></span>
                    <span class="gtxt">함께해요</span>
                  </h3>
                  <div class="main">
                    <p class="text">
                      KB 오케어와 <br>
                      당신의 건강한 하루를 <br>
                      더 쉽고 재미있게
                    </p>
                    <p class="m-text" data-only="mobile">
                      KB 오케어와 <br class="mo-br">
                      건강하고 즐거운 오늘
                    </p>
                  </div>
                  <ul class="desc">
                    <li class="on">
                      <span class="num">1</span>
                      <span>10년치 건강검진 조회</span>
                    </li>
                    <li>
                      <span class="num">2</span>
                      <span>검진결과분석 살펴보기</span>
                    </li>
                    <li>
                      <span class="num">3</span>
                      <span>건강 챌린지 참여하기</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="panel">
                <div class="panel-wrap">
                  <div class="mission-list">
                    <div class="mission-content mission-content01">
                      <ul class="scroll-wrap">
                        <li>
                          <img src="@/resources/img/ocare/mission01.png" alt="수면 다짐 5월 미션" />
                        </li>
                        <li>
                          <img src="@/resources/img/ocare/mission02.png" alt="건강검진 6월 미션" />
                        </li>
                        <li>
                          <img src="@/resources/img/ocare/mission03.png" alt="세안 관리 7월 미션" />
                        </li>
                        <li>
                          <img src="@/resources/img/ocare/mission01.png" alt="수면 다짐 5월 미션" />
                        </li>
                        <li>
                          <img src="@/resources/img/ocare/mission02.png" alt="건강검진 6월 미션" />
                        </li>
                        <li>
                          <img src="@/resources/img/ocare/mission03.png" alt="세안 관리 7월 미션" />
                        </li>
                      </ul>
                    </div>
                    <div class="mission-content mission-content02">
                      <ul class="scroll-wrap">
                        <li>
                          <img src="@/resources/img/ocare/mission04.png" alt="치과 방문 4월 미션" />
                        </li>
                        <li>
                          <img src="@/resources/img/ocare/mission05.png" alt="취미 실천 9월 미션" />
                        </li>
                        <li>
                          <img src="@/resources/img/ocare/mission06.png" alt="피부 관리 7월 미션" />
                        </li>
                        <li>
                          <img src="@/resources/img/ocare/mission04.png" alt="치과 방문 4월 미션" />
                        </li>
                        <li>
                          <img src="@/resources/img/ocare/mission05.png" alt="취미 실천 9월 미션" />
                        </li>
                        <li>
                          <img src="@/resources/img/ocare/mission06.png" alt="피부 관리 7월 미션" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide class="section" data-section-group="04" data-section="0402">
              <div class="title-area">
                <h2 class="h2">Section 0402. 미션하고 인증하고 스템프 찍기</h2>
              </div>
              <div class="comp fixed-comp" data-only="mobile">
                <div class="governing">
                  <h3 class="icon-title">
                    <span class="round"><i class="icon calendar2"></i><em>스마트하게</em></span>
                    <span class="gtxt">함께해요</span>
                  </h3>
                  <div class="main">
                    <p class="text">
                      KB 오케어와 <br>
                      당신의 건강한 하루를 <br>
                      더 쉽고 재미있게
                    </p>
                    <p class="m-text" data-only="mobile">
                      KB 오케어와 <br class="mo-br">
                      건강하고 즐거운 오늘
                    </p>
                  </div>
                  <ul class="desc">
                    <li>
                      <span class="num">1</span>
                      <span>10년치 건강검진 조회</span>
                    </li>
                    <li class="on">
                      <span class="num">2</span>
                      <span>검진결과분석 살펴보기</span>
                    </li>
                    <li>
                      <span class="num">3</span>
                      <span>건강챌린지 참여하기</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="panel">
                <div class="panel-wrap">
                  <div class="stamp">
                    <div class="stamp-wrap">

                      <div class="stamp-complete">
                        <img src="@/resources/img/ocare/mission01.png" alt="" class="healthcard-1">
                        <img src="@/resources/img/ocare/mission05.png" alt="" class="healthcard-2">
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide class="section" data-section-group="04" data-section="0403">
              <div class="title-area">
                <h2 class="h2">Section 0403. 스탬프 다 모으면 경품 득템!</h2>
              </div>
              <div class="comp fixed-comp" data-only="mobile">
                <div class="governing">
                  <h3 class="icon-title">
                    <span class="round"><i class="icon calendar"></i><em>즐겁게</em></span>
                    <span class="gtxt">함께해요</span>
                  </h3>
                  <div class="main">
                    <p class="text">
                      KB 오케어와 <br>
                      당신의 건강한 하루를 <br>
                      더 쉽고 재미있게
                    </p>
                    <p class="m-text" data-only="mobile">
                      KB 오케어와 <br class="mo-br">
                      건강하고 즐거운 오늘
                    </p>
                  </div>
                  <ul class="desc">
                    <li>
                      <span class="num">1</span>
                      <span>10년치 건강검진 조회</span>
                    </li>
                    <li>
                      <span class="num">2</span>
                      <span>검진결과분석 살펴보기</span>
                    </li>
                    <li class="on">
                      <span class="num">3</span>
                      <span>건강 챌린지 참여하기</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="panel">
                <div class="panel-wrap">
                  <div class="magic">
                    <div class="ani-magic">
                      <div class="magic-group gifts">
                        <div class="group-wrap">
                          <span class="magic-gift01"></span>
                          <span class="magic-gift02"></span>
                          <span class="magic-gift03"></span>
                          <span class="magic-gift04"></span>
                          <span class="magic-gift05"></span>
                          <span class="magic-gift06"></span>
                          <span class="magic-gift07"></span>
                        </div>
                      </div>
                      <div class="magic-group hands">
                        <div class="group-wrap">
                          <span class="magic-ticket"></span>
                          <span class="magic-hand"></span>
                        </div>
                      </div>
                      <span class="magic-deco"></span>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="section" data-section="06">
              <div class="title-area">
                <h2 class="h2">Section 06. 루틴</h2>
              </div>
              <div class="comp">
                <div class="governing">
                  <h3 class="icon-title ta-c">
                    <span class="round"><i class="icon weather"></i><em>일상속에서</em></span>
                    <span class="gtxt">함께해요</span>
                  </h3>
                  <div class="main">
                    <p>
                      나만의 루틴을 실천할 수 <br class="mo-br" />있도록<br class="pc-br" />
                      건강 메이커 오케어가 <br class="mo-br" />도와드릴게요
                    </p>
                  </div>
                </div>
                <div class="routine">
                  <div class="routine-wrap">
                    <div class="routine-center">
                      <div class="center-wrap">
                        <div class="center-ani">
                          <img
                            class="loader"
                            src="@/resources/img/ocare/routine_loader.gif"
                            alt=""
                          />
                          <div class="info">
                            <span>루틴 달성률(%)</span>
                            <strong>88</strong>
                          </div>
                        </div>
                        <p>점점 더 건강해지고 있어요!</p>
                      </div>
                    </div>

                    <div class="routine-area">
                      <swiper
                        class="swiperRoutine"
                        v-bind="swiperRoutineOptions"
                        :modules="modules"
                        @swiper="onRutineSwiper"
                      >
                        <swiper-slide class="icon01"><span>걷기</span></swiper-slide>
                        <swiper-slide class="icon02"><span>체중</span></swiper-slide>
                        <swiper-slide class="icon03"><span>식사</span></swiper-slide>
                        <swiper-slide class="icon04"><span>수면</span></swiper-slide>
                        <swiper-slide class="icon05"><span>운동</span></swiper-slide>
                        <swiper-slide class="icon06"><span>음수</span></swiper-slide>
                        <swiper-slide class="icon07"><span>복약</span></swiper-slide>
                        <swiper-slide class="icon08"><span>음주</span></swiper-slide>
                        <swiper-slide class="icon09"><span>명상</span></swiper-slide>
                        <swiper-slide class="icon10"><span>반신욕</span></swiper-slide>
                        <swiper-slide class="icon11"><span>일기</span></swiper-slide>
                        <swiper-slide class="icon12"><span>독서</span></swiper-slide>
                        <swiper-slide class="icon01"><span>걷기</span></swiper-slide>
                        <swiper-slide class="icon02"><span>체중</span></swiper-slide>
                        <swiper-slide class="icon03"><span>식사</span></swiper-slide>
                        <swiper-slide class="icon04"><span>수면</span></swiper-slide>
                        <swiper-slide class="icon05"><span>운동</span></swiper-slide>
                        <swiper-slide class="icon06"><span>음수</span></swiper-slide>
                        <swiper-slide class="icon07"><span>복약</span></swiper-slide>
                        <swiper-slide class="icon08"><span>음주</span></swiper-slide>
                        <swiper-slide class="icon09"><span>명상</span></swiper-slide>
                        <swiper-slide class="icon10"><span>반신욕</span></swiper-slide>
                        <swiper-slide class="icon11"><span>일기</span></swiper-slide>
                        <swiper-slide class="icon12"><span>독서</span></swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide class="section" data-section="swiper-empty">
              <div class="title-area">
                <h2 class="h2">Section 전환용 빈 프레임</h2>
              </div>
              <div class="comp"></div>
            </swiper-slide>
          </swiper>
        </div>
        <!-- //swiperSection -->

        <!-- bodySection -->
        <div class="bodySection">
          <div class="body-wrapeer">
            <div class="section" data-section="07">
              <div class="title-area">
                <h2 class="h2">Section 07. 맞춤 프로그램 추천</h2>
              </div>
              <div class="comp">
                <div class="governing">
                  <h3 class="icon-title ta-c">
                    <span class="round"><i class="icon microscope"></i><em>전문적으로</em></span>
                    <span class="gtxt">함께해요</span>
                  </h3>
                  <div class="main">
                    <p>
                      나에게 딱 맞는 <br />
                      헬스케어 프로그램을 만나보세요
                    </p>
                  </div>
                </div>
                <div class="program">
                  <ul class="program-list">
                    <li>
                      <div class="program-item">
                        <div class="img-area">
                          <img src="@/resources/img/ocare/program01.png" alt="" />
                        </div>
                        <div class="desc-area">
                          <strong>홈핏</strong>
                          <p>
                            회원님에게 건강과 여가를 선물하는 건강 분야의 올인원 전문가 매칭
                            서비스예요.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="program-item">
                        <div class="img-area">
                          <img src="@/resources/img/ocare/program04.png" alt="" />
                        </div>
                        <div class="desc-area">
                          <strong>현대그린푸드</strong>
                          <p>
                            나의 영양섭취 점수를 알아보고, 내 건강 상태에 맞는 건강식을 이용해
                            보세요.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="program-item">
                        <div class="img-area">
                          <img src="@/resources/img/ocare/program10.png" alt="" />
                        </div>
                        <div class="desc-area">
                          <strong>휴노</strong>
                          <p>말 못할 고민이 있으시다면 휴노 심리 상담을 통해 털어놓으세요.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="program-item">
                        <div class="img-area">
                          <img src="@/resources/img/ocare/program08.png" alt="" />
                        </div>
                        <div class="desc-area">
                          <strong>테라젠헬스</strong>
                          <p>타고난 유전적 특성에 따른 맞춤형 생활 가이드를 안내해요.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="program-item">
                        <div class="img-area">
                          <img src="@/resources/img/ocare/program03.png" alt="" />
                        </div>
                        <div class="desc-area">
                          <strong>탱그램팩토리</strong>
                          <p>
                            새로운 경험 데이터 기반 운동 관리, 최상의 운동 효과를 내는 홈 트레이닝
                            프로그램이에요.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="program-item">
                        <div class="img-area">
                          <img src="@/resources/img/ocare/program09.png" alt="" />
                        </div>
                        <div class="desc-area">
                          <strong>스포애니</strong>
                          <p>
                            스포애니는 언제 어디서나, 누구나 편리하고 부담 없이 이용할 수 있는
                            스포츠입니다.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="program-item">
                        <div class="img-area">
                          <img src="@/resources/img/ocare/program02.png" alt="" />
                        </div>
                        <div class="desc-area">
                          <strong>비컨</strong>
                          <p>1분 두피 건강 설문으로 내 두피에 맞는 솔루션을 추천받을 수 있어요.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="program-item">
                        <div class="img-area">
                          <img src="@/resources/img/ocare/program07.png" alt="" />
                        </div>
                        <div class="desc-area">
                          <strong>사운드짐</strong>
                          <p>온라인 코치와 함께 나만의 맞춤 운동을 시작해 보세요.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="program-item">
                        <div class="img-area">
                          <img src="@/resources/img/ocare/program05.png" alt="" />
                        </div>
                        <div class="desc-area">
                          <strong>휴레이포지티브</strong>
                          <p>
                            출근 전부터 퇴근 후까지 관리해주는 휴레이포지티브로 나만의 건강 습관을
                            만들어 나갈 수 있어요.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="program-item">
                        <div class="img-area">
                          <img src="@/resources/img/ocare/program06.png" alt="" />
                        </div>
                        <div class="desc-area">
                          <strong>케어닥</strong>
                          <p>
                            편리하게 간병인을 찾아 안심하고 맡길 수 있는 시니어 플랫폼 케어닥이에요.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="section" data-section="08">
              <div class="title-area">
                <h2 class="h2">Section 08. App DOWNLOAD</h2>
              </div>
              <div class="comp">
                <div class="governing">
                  <h3 class="main">
                    <p>자, 이제 오케어와<br class="pc-br" /> 함께해 볼까요?</p>
                  </h3>
                  <div class="btn-area">
                    <a
                      href="https://apps.apple.com/kr/app/id1597675315"
                      class="btn app apple"
                      target="_blank"
                      title="새창열림"
                      ><span>App Store</span></a
                    >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.kb.ocare.app"
                      class="btn app google"
                      target="_blank"
                      title="새창열림"
                      ><span>Google Play</span></a
                    >
                  </div>
                </div>
                <div class="qr-code">
                  <div class="qr-wrap">
                    <div class="deco"></div>
                    <img
                      class="ios"
                      src="@/resources/img/ocare/qr.png"
                      alt="오케어 App Store & Google Play 바로가기 QR 코드"
                    />
                  </div>
                </div>
              </div>
            </div>

            <footerPage />
          </div>
        </div>
        <!-- //bodySection -->

        <!-- pdf -->
        <!-- PDF 수급 문제로 잠시 display none 처리 함-->
        <div style="display: none" class="pdf">
          <div class="pdf-wrapper">
            <a href="javascript:void(0);" @click="downloadPdf">
              <span class="pdf-txt">
                <span class="blind">우리 회사에도 도입하고 싶어요. PDF Download</span>
              </span>
            </a>
          </div>
        </div>
        <!-- //pdf -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
// import { Vue3Lottie } from "vue3-lottie";
import UIlottieJSON from "@/resources/js/ui.lottie.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Parallax, Mousewheel, Pagination, Keyboard, Autoplay, Navigation } from "swiper/modules";
import { UI } from "@/resources/js/ui.common";
import { UI_Ocare } from "@/resources/js/ui.ocare";
import * as file from "@/api/file";
export default {
  components: { Swiper, SwiperSlide },
  setup() {
    return {
      modules: [Keyboard, Parallax, Mousewheel, Pagination, Autoplay, Navigation],
    };
  },
  data() {
    return {
      UIlottieJSON,
      lottieAnimation03: ref(null),
      lottieAnimation0302: ref(null),
      lottieAnimation0303: ref(null),
      lottieAnimation0304: ref(null),
      swiperRoutineOptions: {
        slidesPerView: "auto",
        spaceBetween: 16,
        loop: true,
        autoplay: {
          delay: 1600,
          disableOnInteraction: false,
        },
      },
      onSlideChange2Options: {
        slidesPerView: "auto",
        spaceBetween: 250,
        centeredSlides: true
      }
    };
  },
  mounted() {
    UI.init(this);
    UI_Ocare.init(this);
  },
  beforeUnmount() {
    UI_Ocare.clear();
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange(swiper) {
      UI_Ocare.change(swiper.activeIndex);
      if (swiper.activeIndex >= 1 && swiper.activeIndex <= 6) this.stopLottieAnimation();
    },
    onSwiper2(swiper2) {
      this.swiper2 = swiper2;
    },
    onSlideChange2(swiper2) {
      UI_Ocare.change(swiper2.activeIndex);
      const videos = document.querySelectorAll('video');
      videos[0].play();
      videos[1].pause();
      videos[2].pause();
      Array.prototype.forEach.call(videos, function(video){
        video.pause()
        videos[swiper2.activeIndex].play(); 
        videos[swiper2.activeIndex].muted = false;
      });
    },

    slideTo(goSlide) {
      this.swiper.slideTo(goSlide);
    },
    onRutineSwiper(swiper) {
      this.swiper = swiper;
      const swiperRutine = document.querySelector(".swiperRoutine");
      swiperRutine.style.width = window.innerWidth + "px";
    },
    // playLottieAnimation(num) {
    //    this.$refs["lottieAnimation" + num].goToAndPlay(0);
    //    this.$refs["lottieAnimation" + num].setSpeed(1.4);
    // },
    stopLottieAnimation() {
      // this.$refs.lottieAnimation03.goToAndStop(0);
      // this.$refs.lottieAnimation0302.goToAndStop(0);
      // this.$refs.lottieAnimation0303.goToAndStop(0);
      // this.$refs.lottieAnimation0304.goToAndStop(0);
    },
    downloadPdf() {
      file._getTemlatePath("CNTRT_DOC", "test.pdf", "_blank");
    },
  },
};
</script>
